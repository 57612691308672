<template>
  <div class="">
    <transition appear mode="out-in" enter-class="w-8/12 ease-out transform -translate-x-1/2 -translate-y-1/2 top-1/2 h-120 left-1/2" enter-active-class="transition-all duration-500">
      <img :src="recipe.imageUrl" class="absolute inset-x-0 object-cover object-center w-full h-48 opacity-75 top-16" v-if="recipe.imageUrl">
    </transition>
    <transition appear mode="out-in" enter-class="transform translate-y-1/2 opacity-0" enter-active-class="transition-all duration-1000 delay-300">
      <div :class="['container flex flex-col max-w-md px-4 pt-8 mx-auto', {'mt-48': recipe.imageUrl}]">
        <div class="flex">
          <div class="flex items-center w-1/2 text-sm text-gray-200" v-if="recipe.time">
            <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            {{recipe.time}}
          </div>
          <div class="flex items-center justify-end w-1/2 ml-auto space-x-1">
            <svg v-for="n in 5" :key="n" :class="['w-4 h-4', n <= recipe.level ? 'text-primary-400' : 'text-gray-400']" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"></path></svg>
          </div>
        </div>
      </div>
    </transition>
    <transition appear mode="out-in" enter-class="transform translate-y-1/2 opacity-0" enter-active-class="transition-all duration-1000 delay-500">
      <div class="container flex flex-col max-w-md px-4 pt-2 mx-auto">
        <div class="flex flex-wrap mb-6">
          <span class="flex items-center justify-center px-2 mr-2 text-sm rounded-full bg-primary-600 py-2px" v-for="category in recipe.categories" :key="category.id">
            {{category.title}}
          </span>
        </div>
        <div class="mb-2">
          <h2 class="text-xl font-semibold tracking-wider text-white opacity-50">Zutaten</h2>
        </div>
        <div class="flex items-center leading-relaxed" v-for="ingredient in recipe.ingredients" :key="ingredient.id">
          <div class="w-1/5">
            <span class="">{{ingredient.qty}}</span>
          </div>
          <div class="flex-1">
            <span class="">{{ingredient.title}}</span>
          </div>
          <button @click="shopIngredient(`${ingredient.qty} ${ingredient.title}`)" class="p-1 ml-2 transition-all duration-100 transform rounded-full text-primary-400 hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"></path></svg>
          </button>
        </div>
      </div>
    </transition>
    <transition appear mode="out-in" enter-class="transform translate-y-1/2 opacity-0" enter-active-class="transition-all duration-1000 delay-500">
      <div class="container flex flex-col max-w-md px-4 pt-8 mx-auto">
        <div class="mb-2">
          <h2 class="text-xl font-semibold tracking-wider text-white opacity-50">Vorbereitung</h2>
        </div>
        <div class="mb-1 leading-tight text-justify whitespace-pre-wrap">
          {{recipe.preparation}}
        </div>
      </div>
    </transition>
    <transition appear mode="out-in" enter-class="transform translate-y-1/2 opacity-0" enter-active-class="transition-all duration-1000 delay-700">
      <div class="container flex flex-col max-w-md px-4 pt-8 mx-auto">
        <div class="mb-2">
          <h2 class="text-xl font-semibold tracking-wider text-white opacity-50">Zubereitung</h2>
        </div>
        <div class="mb-1 leading-tight text-justify whitespace-pre-wrap">
          {{recipe.instructions}}
        </div>
      </div>
    </transition>

    <transition appear mode="out-in" enter-class="transform scale-50 rotate-180 opacity-0" enter-active-class="transition-all duration-500 delay-1000">
      <button @click="$router.push(`/recipes/edit/${$route.params.id}`)" class="fixed p-4 text-white rounded-full shadow bg-primary-600 bottom-8 right-6 focus:outline-none">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
      </button>
    </transition>

    <modal :open="toShop" @close="toShop = false">
      <template #title>{{toShopTitle}}</template>
      <div class="flex items-center justify-end mt-8">
        <button @click="toShop = false" class="flex-1 px-4 py-2 mr-4 text-sm bg-gray-700 rounded-md sm:flex-initial focus:outline-none">Zurück</button>
        <button @click="shopIngredientGrocery()" class="flex-1 px-4 py-2 text-sm rounded-md bg-primary-600 sm:flex-initial focus:outline-none">Auf die Einkaufsliste</button>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from '@/components/modal'

export default {
  components: { modal },
  data: () => ({
    toShop: false,
    toShopTitle: null
  }),
  computed: {
    recipe: vm => vm.$store.getters['recipes/single'](vm.$route.params.id)
  },
  methods: {
    shopIngredient(title) {
      this.toShopTitle = title
      this.toShop = true
    },
    shopIngredientGrocery() {
      this.$store.dispatch('shoppinglist/addItemGrocery', this.toShopTitle)
      this.toShop = false
      this.$toast(`${this.toShopTitle} wurde auf die Einkaufliste gesetzt`)
    }
  }
}
</script>

<style scoped>
.h-recipe {
  height: calc(100vh - 12rem);
}
</style>